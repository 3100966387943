<template>
  <section id="IMAG">
    <ExperiencesCarousel :carouselImages="carouselImages" />
  </section>
</template>

<script>
  import ExperiencesCarousel from '@/components/global/ExperiencesCarousel'

  export default {
    name: "IMAG",
    components: {
      ExperiencesCarousel
    },
    data() {
      return {
        carouselImages: [
			{
				image: 'Experiences/imag/imag-01.jpg',
				title: 'IMAG',
				description: "Your guest’s name in lights! Build excitement and energy as you feature your guests and activations on the video boards. This experience is also ideal for speakers and will enhance any presentation or performance.",
			},
			{
				image: 'Experiences/imag/imag-02.jpg',
			},
			{
				image: 'Experiences/imag/imag-03.jpg',
			},
			{
				image: 'Experiences/imag/imag-04.jpg',
			},
			{
				image: 'Experiences/imag/imag-05.jpg',
			}
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>
